<template>
  <div class="widgetContainer widgetContainer--center login">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <span class="icon-Arrow-big-left cancel" @click="cancel" />
        <p class="title">
          {{ $t('mobileNo_NavTitle') }}
        </p>
      </div>
    </div>
    <div class="widgetContainer__body">
      <el-form
        @keyup.enter.native="submitForm()"
        :model="user"
        :rules="rules"
        ref="user"
        @submit.prevent.native
        class="wise-form">
        <el-form-item prop="phone" :label="$t('mobileNo_Title')">
          <MobileInput
            :value="user.phone"
            :show-country-code-selector="true"
            @input="mobileNumberUpdate"
            :autofocus="true" />
        </el-form-item>
      </el-form>
      <div class="widgetContainer__footer--fixed border-0">
        <el-button
          data-testid="submit"
          type="primary"
          @click="submitForm()"
          :disabled="!isValid"
          class="el-button__brand brand width-100">
          {{ $t('next') }}
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
import MobileInput from '@/components/MobileInput';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'Login',
  components: {
    MobileInput
  },
  data() {
    return {
      isValid: false,
      user: {
        phone: ''
      },
      rules: {
        phone: 'required'
      }
    };
  },
  computed: {
    ...mapGetters('user', ['getUserDetails']),
  },
  methods: {
    ...mapActions('user', ['login']),
    submitForm() {
      if (!this.isValid) {
        return true;
      }
      const loader = this.showLoader();
      this.login(this.user.phone)
        .then(
          /**@param {import('@/modules/user/store/state').userState} user */
          () => {
            this.$router.push('/user/loginOtp');
          }
        )
        .catch((e) => {
          console.log(e);
          this.apiErrorMessage(e);
        })
        .finally(() => {
          loader.close();
        });
    },
    cancel() {
      this.$router.push('/welcome');
    },
    mobileNumberUpdate(v) {
      if(v) {
        const {value, isValid} = v;
        this.user.phone = value;
        this.isValid = isValid;
      }
    }
  }
};
</script>
<style lang="scss">
  .widgetContainer {
    &.login {
      .widgetContainer__body {
        padding-top: 30px;
        position: relative;
      }
    }
  }
</style>