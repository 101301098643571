<template>
  <div class="mobile-redirect">
    <div class="mobile-redirect__body">
      <BrandImg
        class="mobile-redirect__logo"
        src="logos/Logo.svg"
        data-testid="logo"
        alt="logo" />
      <p class="mobile-redirect__info">
        For a smoother experience, open on a desktop browser or download the app
      </p>
      <div class="mobile-redirect__logos">
        <a @click="redirect('ios')" :href="urls.ios.url" v-if="urls.ios && urls.ios.url && urls.ios.url !== '' ">
          <img src="@/assets/images/Apple-store.svg"
               alt="Apple Store">
        </a>
        <a @click="redirect('android')" :href="urls.android.url" v-if="urls.android && urls.android.url && urls.android.url !== '' ">
          <img src="@/assets/images/Android-store.svg"
               alt="Android Store">
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import { getBrandConfig } from '@/utils/env'
import BrandImg from '@/components/BrandImg.vue';
export default {
  computed: {
    urls() {
      const urls = getBrandConfig('appUrl', {});
      return urls;
    }
  },
  components: {
    BrandImg
  },
  methods: {
    redirect(platform) {
      const {urlScheme, url} =  this.urls[platform];
      if(urlScheme !== '')
        window.location.href = urlScheme;

      setTimeout(() => window.location.href = url, 3000); //fallback
    }
  }
}
</script>
<style lang="scss">
  .mobile-redirect {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    
    &__body {
      display: flex;
      align-items: center;
      flex-direction: column;

      @media (max-width: 767.9px) {
        justify-content: center;
        height: 100%;
      }
    }
    
      &__logos {
        a {
          display: inline-block;
          
          &:first-of-type {
            margin-right: 16px;
          }
        }

        @media (max-width: 767.9px) {
          margin-bottom: 70px;
        }
      }

    &__logo {
      width: 175px;
      margin-bottom: 50%;

      @media (max-width: 767.9px) {
        flex: 1;
        margin-bottom: 0;
      }
    }

    &__info {
      max-width: 300px;
      font-weight: 500;
      line-height: 26px;
      text-align: center;
      padding-bottom: 16px;

      @media (max-width: 767.9px) {
        margin-top: auto;
      }
    }
  }
</style>